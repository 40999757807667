const en = {
  actions: {
    created: (model) => `${model} was successfully created.`,
    updated: (model) => `${model} was successfully updated.`,
    removed: (model) => `${model} was successfully removed.`,
  },
  controls: {
    continue: 'Continue',
    confirm: 'Confirm',
    editModel: (model) => `Edit ${model}`,
    remove: 'Remove',
    removeModel: (model) => `Remove ${model}`,
    resume: 'Resume',
    start: 'Start',
    submit: 'Submit',
  },
  flashMessages: {
    login: 'You have successfully logged in.',
    logout: 'You were successfully logged out.',
    unauthorized: 'You are not logged in.',
    genericError: 'An error occurred.',
    wrongAnswer: 'You provided the wrong answer.',
  },
  models: {
    questionnaire: 'questionnaire',
    questionnaires: 'questionnaires',
    question: 'question',
    questions: 'questions',
  },
  list: {
    empty: (model) => `No ${model} were found.`,
  },
  questionnaire: {
    title: 'Title',
    completedCount: 'Completed',
    nQuestions: (n) => `${n} questions`,
    questionsCount: 'Nr. of questions',
  },
  questionnaireRun: {
    success: {
      title: 'Congratulations',
      message: 'You successfully finished this questionnaire.',
      button: 'Continue',
    },
    error: {
      updateQuestionHint: 'Found an error? Fix it right now.',
    },
  },
};

export const i18n = {
  en,
};
