import { IFormState } from '../common';

export const formChangeHandler = ({
  event,
  formState,
  setFormState,
}: {
  event: React.ChangeEvent<HTMLInputElement>;
  formState: IFormState;
  setFormState: (IFormState) => void;
}) => {
  const el = event.currentTarget;
  const key = el.name;
  setFormState({
    ...formState,
    [key]: el.value,
  });
};
