import React, { useCallback, useState } from 'react';
import { createContext, useMemo } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export interface IFlashMessage {
  text: string;
  style: 'success' | 'error';
}

export interface IFlashMessageContext {
  setMessage: (smg: IFlashMessage) => void;
}

export const FlashMessageContext = createContext<IFlashMessageContext>({
  setMessage: () => ({}),
});

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export function FlashMessageProvider({ children }: { children: React.ReactNode }) {
  const [flashMessage, setFlashMessage] = useState<IFlashMessage>();
  const [open, setOpen] = useState<boolean>(false);
  const { palette } = useTheme();

  const setMessage = useCallback((msg) => {
    setFlashMessage(msg);
    setOpen(true);
  }, []);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getBackgroundColor = (flashMessage: IFlashMessage | undefined) => {
    if (flashMessage) {
      switch (flashMessage.style) {
        case 'error':
          return palette.error;
        case 'success':
        default:
          return palette.success;
      }
    }
    return palette.success;
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const notification = useMemo(() => ({ setMessage }), [setMessage]);
  return (
    <FlashMessageContext.Provider value={notification}>
      <>
        {flashMessage ? (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={4000}
            onClose={handleClose}
            message={capitalize(flashMessage.text)}
            action={action}
            ContentProps={{
              style: {
                background: getBackgroundColor(flashMessage).light,
              },
            }}
          />
        ) : null}
        {children}
      </>
    </FlashMessageContext.Provider>
  );
}
