import React, { useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const FixedBottom = ({
  bottomContent,
  children,
}: {
  bottomContent: React.ReactNode;
  children: React.ReactNode;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const bottomContentRef = useRef<HTMLDivElement>(null);
  const { palette, spacing } = useTheme();

  useEffect(() => {
    if (containerRef && containerRef.current && bottomContentRef && bottomContentRef.current) {
      const height = bottomContentRef.current.clientHeight;
      containerRef.current.style.paddingBottom = `calc(${height}px + ${spacing(2)})`;
    }
  }, [bottomContentRef, containerRef, spacing]);

  return (
    <Grid
      container
      direction="column"
      ref={containerRef}
      style={{
        flexGrow: 1,
        position: 'relative',
      }}
    >
      <Grid
        style={{
          flexGrow: 1,
        }}
      >
        {children}
      </Grid>
      <Grid
        ref={bottomContentRef}
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            borderTop: 1,
            borderColor: 'grey.300',
            p: 2,
            ml: -2,
            mr: -2,
            background: palette.common.white,
          }}
        >
          {bottomContent}
        </Box>
      </Grid>
    </Grid>
  );
};
