import React from 'react';
import { useParams } from 'react-router-dom';
import { useFlash, useRun } from '../../hooks';
import { IPendingQuestionnaireRun } from '../../common';
import { CompletedRun } from '../../components/completedRun';
import { PendingRun } from '../../components/pendingRun';
import { Spinner } from '../../components/spinner';
import { Container } from '@mui/material';

export function RunQuestionnaire() {
  const { runId: id } = useParams();
  const { data: run, error, loading } = useRun(id!);
  const { setMessage } = useFlash();

  if (loading || !run) {
    return <Spinner />;
  }

  if (error) {
    setMessage({ text: error.detail, style: 'error' });
  }

  return (
    <Container maxWidth="sm">
      {run.completed ? <CompletedRun run={run} /> : <PendingRun run={run as IPendingQuestionnaireRun} />}
    </Container>
  );
}
