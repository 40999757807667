import React from 'react';
import { QuestionnaireForm } from '../../components/questionnaireForm';
import { Container } from '@mui/material';

export function NewQuestionnaire() {
  return (
    <Container maxWidth="sm">
      <h1>New</h1>
      <QuestionnaireForm />
    </Container>
  );
}
