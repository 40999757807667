import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuestion } from '../../hooks';
import { QuestionForm } from '../../components/questionForm';
import { Spinner } from '../../components/spinner';
import { Container } from '@mui/material';

export function EditQuestion() {
  const { questionnaireId, questionId } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuestion(questionId!);

  if (loading || !data) {
    return <Spinner />;
  }

  const successHandler = () => {
    navigate(`/questionnaires/${questionnaireId}`);
  };

  return (
    <Container maxWidth="sm">
      <h1>New</h1>
      <QuestionForm questionnaireId={questionnaireId!} question={data} successHandler={successHandler} />
    </Container>
  );
}
