import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuestionnaire } from '../../hooks';
import { QuestionnaireForm } from '../../components/questionnaireForm';
import { Spinner } from '../../components/spinner';
import { Container } from '@mui/material';

export function EditQuestionnaire() {
  const { questionnaireId: id } = useParams();
  const { data: questionnaire, loading } = useQuestionnaire(id);

  if (loading || !questionnaire) {
    return <Spinner />;
  }

  return (
    <Container maxWidth="sm">
      <h1>Edit {questionnaire.title}</h1>
      <QuestionnaireForm questionnaire={questionnaire} />
    </Container>
  );
}
