import React from 'react';
import { useQuestionnaires } from '../../hooks';
import { QuestionnaireList } from '../../components/questionnaireList';
import { Spinner } from '../../components/spinner';
import { Container } from '@mui/material';

function Page() {
  const { data, loading } = useQuestionnaires();

  return (
    <Container maxWidth="sm">
      <h1>Questionnaires</h1>
      {loading ? <Spinner /> : <QuestionnaireList questionnaires={data} />}
    </Container>
  );
}

export default Page;
