import React from 'react';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import { useMatches, useLocation, UIMatch } from 'react-router-dom';
import Link from '../link';
import { IRouteHandle } from '../../common';

type ICrumbs = {
  to?: string;
  text: string;
};

export const BreadCrumbs = () => {
  const matches = useMatches();
  const location = useLocation();

  const crumbs: ICrumbs[] = Object.values(
    matches.reduce((acc, cur: UIMatch<unknown, IRouteHandle>) => {
      if (cur.handle?.crumb) {
        return {
          ...acc,
          [cur.pathname]: {
            text: cur.handle?.crumb(cur),
            to: location.pathname === cur.pathname ? undefined : cur.pathname,
          },
        };
      } else {
        return acc;
      }
    }, {}),
  );

  if (!crumbs.length) {
    return null;
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        pb: 2,
        mb: 2,
      }}
    >
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {Object.values(crumbs).map(({ to, text }) => {
            if (to) {
              return (
                <Link key={text} underline="hover" color="inherit" to={to}>
                  {text}
                </Link>
              );
            } else {
              return (
                <Typography key={text} color="text.primary">
                  {text}
                </Typography>
              );
            }
          })}
        </Breadcrumbs>
      </div>
    </Container>
  );
};
