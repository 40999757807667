import React, { useState } from 'react';
import { i18n } from '../../common';
import { IQuestion } from '../../common';
import { Button, Box, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { QuestionForm } from '../questionForm';
import Modal from '../modal';

export const EditQuestion = ({ question }: { question: IQuestion }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const title = i18n.en.controls.editModel(i18n.en.models.question);

  const successHandler = () => {
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen} startIcon={<Edit />}>
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        ariaLabelledby="update-question-modal-title"
        ariaDescribedby="update-question-modal-description"
      >
        <Typography id="update-question-modal-title" variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography id="update-question-modal-description">
          {i18n.en.questionnaireRun.error.updateQuestionHint}
        </Typography>
        <Box sx={{ mt: 4 }}>
          <QuestionForm question={question} successHandler={successHandler} />
        </Box>
      </Modal>
    </div>
  );
};
