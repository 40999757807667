import React, { useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Stack } from '@mui/material';
import { IQuestionnaire, IDetailedQuestionnaire, i18n, IFormError } from '../../common';
import { usePost, useFlash } from '../../hooks';
import { formChangeHandler } from '../../utils';

export function QuestionnaireForm({ questionnaire }: { questionnaire?: IDetailedQuestionnaire }) {
  const [formState, setFormState] = useState({
    title: questionnaire?.title || '',
  });
  const [errors, setErrors] = useState<IFormError>({});

  const { post } = usePost<IQuestionnaire>();
  const { setMessage } = useFlash();
  const navigate = useNavigate();

  const model = i18n.en.models.questionnaire;
  const notification = questionnaire ? i18n.en.actions.updated : i18n.en.actions.created;

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    const url = `/questionnaires/${questionnaire ? `${questionnaire.id}/` : ''}`;
    const { data, error, errors } = await post(url, {
      body: formState,
      method: questionnaire ? 'PUT' : undefined,
    });
    setErrors(errors || {});
    if (!error && !errors) {
      setMessage({ text: notification(model), style: 'success' });
      navigate(`/questionnaires/${data.id}`);
    }
  };

  const onChangeHandler = (event) => formChangeHandler({ event, formState, setFormState });

  return (
    <form onSubmit={submitHandler}>
      <Stack spacing={2}>
        <TextField
          onChange={onChangeHandler}
          id="title"
          name="title"
          label="Title"
          variant="outlined"
          value={formState.title}
          error={Boolean(errors['title'])}
          helperText={errors['title']}
          autoComplete="off"
        />
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
}
