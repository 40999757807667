import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IPendingQuestionnaireRun, i18n } from '../../common';
import { useFlash, useQuestionnaire, useRunByQuestionnaire, usePost } from '../../hooks';
import ButtonLink from '../../components/buttonLink';
import { Spinner } from '../../components/spinner';
import { FixedBottom } from '../../components/fixedBottom';
import { QuestionList } from '../../components/questionList';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

export function QuestionnaireDetail() {
  const { questionnaireId: id } = useParams();
  const { data: questionnaire, loading } = useQuestionnaire(id!);
  const { data: run, loading: loadingRun } = useRunByQuestionnaire(id!);
  const { post } = usePost<IPendingQuestionnaireRun>();
  const { setMessage } = useFlash();
  const navigate = useNavigate();
  if (loading || !questionnaire) {
    return <Spinner />;
  }

  const runId = run && 'id' in run ? run.id : null;

  const handleCreateRun = async (url: string) => {
    const { data, error } = await post(url);
    if (error) {
      setMessage({ text: error.detail, style: 'error' });
    } else {
      navigate(`/run/${data.id}`);
    }
  };

  const runButton = () => {
    if (runId) {
      return <ButtonLink href={`/run/${runId}`}>{i18n.en.controls.resume}</ButtonLink>;
    } else {
      return (
        <Button onClick={() => handleCreateRun(`/questionnaires/${questionnaire.id}/start`)} variant="contained">
          {i18n.en.controls.start}
        </Button>
      );
    }
  };

  const bottomContent = (
    <Container maxWidth="sm">
      <Grid container direction="column">
        {loadingRun ? (
          <Button variant="contained" disabled>
            <CircularProgress />
          </Button>
        ) : (
          runButton()
        )}
      </Grid>
    </Container>
  );

  const row = (key, value) => (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {key}
      </TableCell>
      <TableCell align="right">{value}</TableCell>
    </TableRow>
  );

  return (
    <FixedBottom bottomContent={bottomContent}>
      <Container maxWidth="sm">
        <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <Grid item>
            <h1>{questionnaire.title}</h1>
          </Grid>
          <Grid item>
            <ButtonLink href={`/questionnaires/${questionnaire.id}/edit`} variant="text">
              <Edit />
            </ButtonLink>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableBody>
              {row(i18n.en.questionnaire.questionsCount, questionnaire.question_count)}
              {row(i18n.en.questionnaire.completedCount, questionnaire.completed_count)}
            </TableBody>
          </Table>
        </TableContainer>
        <QuestionList questions={questionnaire.questions} questionnaireId={questionnaire.id} />
      </Container>
    </FixedBottom>
  );
}
