import React, { forwardRef, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button as MuiButton, ButtonProps as MuiButtonProbs } from '@mui/material';

export interface IButtonProps extends MuiButtonProbs {
  href?: string;
  children: ReactNode;
}

const ButtonLink = forwardRef<HTMLButtonElement, IButtonProps>(({ href, children, ...props }, ref) => {
  return (
    <MuiButton component={RouterLink} to={href} ref={ref} {...props}>
      {children}
    </MuiButton>
  );
});

ButtonLink.displayName = 'app-button-link';

export default ButtonLink;
