import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';

export const Layout = () => {
  return (
    <>
      <Grid
        style={{
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Grid>
    </>
  );
};
