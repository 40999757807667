import React from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '../common';
import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material';
import { useAuth, useFlash } from '../hooks';
import { BreadCrumbs } from '../components/breadCrumbs';

export const AuthorizedLayout = ({ children }: { children: React.ReactNode }) => {
  const { logout } = useAuth();
  const { setMessage } = useFlash();
  const logoutHandler = () => {
    logout();
    setMessage({ text: i18n.en.flashMessages.logout, style: 'success' });
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, mb: 4 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link
                to="/questionnaires"
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Home
              </Link>
            </Typography>
            <Button onClick={logoutHandler} color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <BreadCrumbs />
      {children}
    </>
  );
};
