import React, { ReactNode } from 'react';
import { Box, Modal as MuiModal } from '@mui/material';

const style = {
  position: 'absolute',
  boxSizing: 'border-box',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '100%',
  p: 3,
};

interface ModalProps {
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  onClose: () => void;
  open: boolean;
  children: ReactNode;
}

const Modal = ({ ariaLabelledby, ariaDescribedby, children, onClose, open }: ModalProps) => {
  return (
    <MuiModal open={open} onClose={onClose} aria-labelledby={ariaLabelledby} aria-describedby={ariaDescribedby}>
      <Box sx={style}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 3,
            boxShadow: 24,
          }}
        >
          {children}
        </Box>
      </Box>
    </MuiModal>
  );
};

export default Modal;
