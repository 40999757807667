import { useAuth } from './useAuth';

export const useRequestHeaders = () => {
  const { accessToken } = useAuth();
  const headers = new Headers({ 'Content-type': 'application/json' });

  if (accessToken && accessToken.token) {
    headers.set('Authorization', `Bearer ${accessToken.token}`);
  }

  return headers;
};
