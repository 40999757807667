import { IDetailedQuestionnaire, IQuestionnaire } from '../../common';
import { useGet, useGetList } from '../useGet';

export const useQuestionnaires = () => {
  return useGetList<IQuestionnaire>('/questionnaires/');
};

export const useQuestionnaire = (id: string) => {
  return useGet<IDetailedQuestionnaire>(`/questionnaires/${id}`);
};
