import React from 'react';
import { IQuestionnaire, i18n } from '../../common';
import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { Add, Visibility } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import ButtonLink from '../buttonLink';

export function QuestionnaireList({ questionnaires }: { questionnaires: IQuestionnaire[] }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{i18n.en.questionnaire.title}</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              <ButtonLink variant="contained" href={`/questionnaires/new`} color="success">
                <Add />
              </ButtonLink>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!questionnaires.length ? (
            <TableRow>
              <TableCell colSpan={2}>{i18n.en.list.empty(i18n.en.models.questionnaires)}</TableCell>
            </TableRow>
          ) : (
            questionnaires.map((questionnaire) => {
              const batchColor = questionnaire.completed_count ? 'primary' : 'default';
              const batchStyle = questionnaire.completed_count
                ? null
                : {
                    '.MuiBadge-badge': {
                      backgroundColor: grey[200],
                    },
                  };
              return (
                <TableRow
                  key={questionnaire.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography>
                      <Badge badgeContent={String(questionnaire.completed_count)} color={batchColor} sx={batchStyle}>
                        {questionnaire.title}
                      </Badge>
                    </Typography>
                    <Typography variant="caption">
                      {i18n.en.questionnaire.nQuestions(questionnaire.question_count)}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <ButtonLink href={`/questionnaires/${questionnaire.id}`} variant="text">
                      <Visibility />
                    </ButtonLink>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
