import { useEffect, useState } from 'react';
import { useRequestHeaders } from './useHeaders';
import { useAuth } from '.';
import { IHandleUnauthorized } from '../contexts';
import { CONFIG, IError } from '../common';

type RequestResponse<T> = {
  data: T;
  loading: boolean;
  error?: IError;
};

export type FetchDataParams = {
  url: string;
  params?: string;
  headers: Headers;
  handleUnauthorized: (args?: IHandleUnauthorized | undefined) => void;
  setData: (T) => void;
  setError: (IError) => void;
  setLoading?: (boolean) => void;
};

export const fetchData = async ({
  url,
  params,
  headers,
  handleUnauthorized,
  setData,
  setError,
  setLoading,
}: FetchDataParams) => {
  try {
    const response = await fetch(`${CONFIG.API_ENDPOINT}${url}${params ? `?${params}` : ''}`, {
      method: 'GET',
      headers,
    });
    if (response.status === 401) {
      handleUnauthorized({ notify: true });
    }
    const result = await response.json();
    setData(result);
  } catch (error) {
    setError(error);
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const useGet = <T>(url: string, initialValue?: T, params?: string): RequestResponse<T | null> => {
  const [data, setData] = useState<T | null>(initialValue || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const headers = useRequestHeaders();
  const { handleUnauthorized } = useAuth();

  useEffect(() => {
    fetchData({
      url,
      params,
      headers,
      handleUnauthorized,
      setData,
      setError,
      setLoading,
    });
  }, [url, handleUnauthorized, params]);

  return { data, loading, error };
};

export const useGetList = <T>(url: string, params?: string): RequestResponse<T[]> => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const headers = useRequestHeaders();
  const { handleUnauthorized } = useAuth();

  useEffect(() => {
    fetchData({
      url,
      params,
      headers,
      handleUnauthorized,
      setData,
      setError,
      setLoading,
    });
  }, [url, handleUnauthorized, params]);
  return { data, loading, error };
};
