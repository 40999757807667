import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPendingQuestionnaireRun, IQuestionnaire, IQuestionnaireRun, IQuestionnaireWave, i18n } from '../../common';
import { useAuth, useFlash, usePost } from '../../hooks';
import {
  Alert,
  Button,
  Box,
  Chip,
  Container,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Stack,
  Typography,
} from '@mui/material';
import { Celebration, Error } from '@mui/icons-material';
import { FixedBottom } from '../fixedBottom';
import { SuccessScreen } from './success';
import { EditQuestion } from './editQuestion';

type Response = {
  success: boolean;
  run: IQuestionnaireRun;
};

const Header = ({ wave, questionnaire }: { wave: IQuestionnaireWave; questionnaire: IQuestionnaire }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate(`/questionnaires/${questionnaire.id}`);
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography gutterBottom>
          <strong>Iteration {wave.iteration} / 3</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Chip label={questionnaire.title} variant="outlined" onClick={handleClick} />
      </Grid>
    </Grid>
  );
};

export const PendingRun = ({ run: initialRun }: { run: IPendingQuestionnaireRun }) => {
  const [wave, setWave] = useState(initialRun.current_question);
  const [run, setRun] = useState<IQuestionnaireRun>(initialRun);
  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState<'inProgress' | 'success' | 'error'>('inProgress');
  const [loading, setLoading] = useState(false);
  const { post } = usePost<Response>();
  const { user } = useAuth();
  const { setMessage } = useFlash();

  const handleSubmitAnswer = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { data, error } = await post(`/run/${run.id}/`, {
      body: { answer },
    });
    if (error) {
      setMessage({ text: error.detail, style: 'error' });
    } else {
      const { run, success } = data;
      setRun(run);
      if (run.completed) {
        setMessage({ text: 'You are done here.', style: 'success' });
      }
      setStatus(success ? 'success' : 'error');
    }
    setLoading(false);
  };

  if (run.completed) {
    return <SuccessScreen />;
  }

  const submitHandler = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    switch (status) {
      case 'inProgress':
        handleSubmitAnswer();
        break;
      case 'error':
      case 'success':
        setAnswer('');
        setWave(run.current_question);
        setStatus('inProgress');
        break;
    }
  };

  const formId = 'run-questionnaire-form';

  const makeHtml = () => {
    const correctAnswer = <p>Correct answer: {wave.question.solution}</p>;
    switch (status) {
      case 'inProgress':
        return (
          <form id={formId} onSubmit={submitHandler}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
              <TextField
                onChange={(e) => setAnswer(e.currentTarget.value)}
                type="text"
                id="answer"
                name="answer"
                label="Answer"
                variant="outlined"
                value={answer}
                autoComplete="off"
                autoCorrect='"off'
                autoCapitalize='off'
                spellCheck='false'
                onFocus={(e: React.SyntheticEvent) => e.currentTarget.setAttribute("autocomplete", "off")}
                required
              />
            </Grid>
          </form>
        );
      case 'error':
        return (
          <>
            <Alert icon={<Error fontSize="inherit" />} severity="error">
              Incorrect!
            </Alert>
            {correctAnswer}
            <p>Your answer: {answer}</p>
            <Divider />
            {user && user.id === wave.question.owner_id ? <EditQuestion question={wave.question} /> : null}
          </>
        );
      case 'success':
        return (
          <>
            <Alert icon={<Celebration fontSize="inherit" />} severity="success">
              Correct!
            </Alert>
            {correctAnswer}
          </>
        );
    }
  };

  const makeButton = () => {
    switch (status) {
      case 'inProgress':
        return (
          <Button type="submit" form={formId} variant="contained">
            {i18n.en.controls.submit}
          </Button>
        );
      case 'error':
      case 'success':
        return (
          <Button variant="contained" onClick={submitHandler}>
            {i18n.en.controls.continue}
          </Button>
        );
    }
  };

  const bottomContent = (
    <Container maxWidth="sm">
      <Grid container direction="column">
        {loading ? (
          <Button type="submit" disabled variant="outlined" startIcon={<CircularProgress size={24} />} />
        ) : (
          makeButton()
        )}
      </Grid>
    </Container>
  );

  return (
    <FixedBottom bottomContent={bottomContent}>
      <Header wave={wave} questionnaire={run.questionnaire} />
      <Box
        sx={{
          pt: 2,
          pb: 4,
        }}
      >
        <Divider />
      </Box>
      <Stack spacing={4}>
        <Typography variant="h5">{wave.question.question}</Typography>
        {makeHtml()}
      </Stack>
    </FixedBottom>
  );
};
