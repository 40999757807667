import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { i18n } from '../common';
import { useAuth, useFlash } from '../hooks';
import { Outlet } from 'react-router-dom';
import { AuthorizedLayout } from '../layouts';

export const Authenticated = () => {
  const { setMessage } = useFlash();
  const { accessToken } = useAuth();
  useEffect(() => {
    if (!accessToken) {
      setMessage({
        text: i18n.en.flashMessages.unauthorized,
        style: 'error',
      });
    }
  }, [accessToken, setMessage]);

  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return (
    <AuthorizedLayout>
      <Outlet />
    </AuthorizedLayout>
  );
};

export const UnAuthenticated = ({ children }: { children: React.ReactNode }) => {
  const { accessToken } = useAuth();
  if (accessToken) {
    return <Navigate to="/questionnaires" />;
  }
  return children;
};
