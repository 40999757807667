import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QuestionForm } from '../../components/questionForm';
import { Container } from '@mui/material';

export function NewQuestion() {
  const { questionnaireId } = useParams();
  const navigate = useNavigate();

  const successHandler = () => {
    navigate(`/questionnaires/${questionnaireId}`);
  };

  return (
    <Container maxWidth="sm">
      <h1>New</h1>
      <QuestionForm questionnaireId={questionnaireId!} successHandler={successHandler} />
    </Container>
  );
}
