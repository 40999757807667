import React, { useState } from 'react';
import { useAuth, useFlash, usePost } from '../../hooks';
import { formChangeHandler } from '../../utils';
import { i18n } from '../../common';
import { Button, Container, TextField, Stack } from '@mui/material';

function Page() {
  const [formState, setFormState] = useState({ username: '', password: '' });
  const { post } = usePost<{ access: string; refresh: string }>();
  const { login } = useAuth();
  const { setMessage } = useFlash();

  const onChangeHandler = (event) => formChangeHandler({ event, formState, setFormState });

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data, error } = await post('/auth/token/', { body: formState });
    if (error) {
      setMessage({ text: error.detail, style: 'error' });
    } else {
      login({ accessToken: data.access, refreshToken: data.refresh });
      setMessage({ text: i18n.en.flashMessages.login, style: 'success' });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ pt: 4 }}>
      <h1>Login</h1>
      <form method="POST" onSubmit={submitHandler}>
        <Stack spacing={2}>
          <TextField
            name="username"
            placeholder="username"
            onChange={onChangeHandler}
            label="Username"
            variant="outlined"
            type="text"
            required
          ></TextField>
          <TextField
            name="password"
            placeholder="password"
            onChange={onChangeHandler}
            label="Password"
            variant="outlined"
            type="password"
            required
          ></TextField>
          <Button type="submit" variant="contained">
            Login
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

export default Page;
