import React, { useState, SyntheticEvent } from 'react';
import { Button, TextField, Stack } from '@mui/material';
import { IQuestion, i18n, IFormError } from '../../common';
import { usePost, useFlash } from '../../hooks';
import { formChangeHandler } from '../../utils';

interface QuestionFormProps {
  question?: IQuestion;
  questionnaireId?: string;
  successHandler: () => void;
}

export function QuestionForm({ question, questionnaireId, successHandler }: QuestionFormProps) {
  const [formState, setFormState] = useState({
    question: question?.question || '',
    solution: question?.solution || '',
  });
  const [errors, setErrors] = useState<IFormError>({});

  const { post } = usePost<IQuestion>();
  const { setMessage } = useFlash();

  const model = i18n.en.models.question;
  const notification = question ? i18n.en.actions.updated : i18n.en.actions.created;

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();

    const urlBase = questionnaireId ? `/questionnaires/${questionnaireId}` : '';
    const url = `${urlBase}/questions${question ? `/${question.id}/` : ''}`;

    const { error, errors } = await post(url, {
      body: formState,
      method: question ? 'PUT' : undefined,
    });

    setErrors(errors || {});
    if (error) {
      setMessage({ text: error.detail, style: 'error' });
    }
    if (!error && !errors) {
      setMessage({ text: notification(model), style: 'success' });
      successHandler();
    }
  };

  const onChangeHandler = (event) => formChangeHandler({ event, formState, setFormState });

  return (
    <form onSubmit={submitHandler}>
      <Stack spacing={2}>
        <TextField
          onChange={onChangeHandler}
          id="question"
          name="question"
          label="Question"
          variant="outlined"
          value={formState.question}
          error={Boolean(errors['question'])}
          helperText={errors['question']}
          autoComplete="off"
        />
        <TextField
          onChange={onChangeHandler}
          id="solution"
          name="solution"
          label="Solution"
          variant="outlined"
          value={formState.solution}
          error={Boolean(errors['solution'])}
          helperText={errors['solution']}
          autoComplete="off"
        />
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
}
