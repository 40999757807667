import React from 'react';
import { createContext, useCallback, useMemo, useEffect, useState } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useFlash, fetchData, usePost } from '../hooks';
import { ITokenStorage, IUser, i18n } from '../common';

export interface IToken {
  token: string;
  expires: Date | null;
}

export type IHandleUnauthorized = {
  notify?: boolean;
  refreshCallback?: () => void;
};

export interface IAuthContext {
  accessToken: IToken | null;
  handleUnauthorized: (args?: IHandleUnauthorized | undefined) => void;
  login: (data: ITokenStorage) => Promise<void>;
  logout: () => void;
  refreshToken: IToken | null;
  user: IUser | undefined;
}

export const AuthContext = createContext<IAuthContext>({
  accessToken: null,
  refreshToken: null,
  login: () => Promise.resolve(),
  logout: () => null,
  handleUnauthorized: () => null,
  user: undefined,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setaccessToken] = useLocalStorage<IToken | null>('accessToken');
  const [refreshToken, setRefreshToken] = useLocalStorage<IToken | null>('refreshToken');
  const [user, setUser] = useState<IUser>();
  const { post } = usePost<{ access: string }>();

  const navigate = useNavigate();
  const { setMessage } = useFlash();

  const getRefreshDate = (token) => {
    const decoded = jwtDecode<JwtPayload>(token);
    if (decoded.exp && decoded.iat) {
      const exp = new Date(decoded.exp);
      const iat = new Date(decoded.iat);
      const diff = exp.getTime() - iat.getTime();
      const now = new Date();
      return new Date(now.setSeconds(now.getSeconds() + diff));
    }
    return null;
  };

  const login = useCallback(
    async (data: ITokenStorage) => {
      const authExpires = getRefreshDate(data.accessToken);
      const refreshExpires = getRefreshDate(data.refreshToken);
      setaccessToken({ token: data.accessToken, expires: authExpires });
      setRefreshToken({
        token: data.refreshToken,
        expires: refreshExpires,
      });
      navigate('/questionnaires');
    },
    [setaccessToken, setRefreshToken, navigate],
  );

  const obtainNewToken = async () => {
    if (refreshToken) {
      const { data, error, errors } = await post('/auth/token/refresh/', { body: { refresh: refreshToken.token } });
      if (error || errors) {
        if (error) {
          setMessage({ text: error.detail, style: 'error' });
        }
      } else {
        const expires = getRefreshDate(data.access);
        console.log(expires)
        console.log(data)
        setaccessToken({ token: data.access, expires });
        return true
      }
    }
    return false
  }

  const logout = useCallback(() => {
    setaccessToken(null);
    setRefreshToken(null);
    navigate('/', { replace: true });
  }, [setaccessToken, setRefreshToken, navigate]);

  const handleUnauthorized = async ({ notify, refreshCallback }: IHandleUnauthorized = {}) => {
    const refreshed = await obtainNewToken();
    if (refreshed) {
      if (refreshCallback) {
        refreshCallback();
      }
    } else {
      if (notify)
        setMessage({
          text: i18n.en.flashMessages.unauthorized,
          style: 'error',
        });
      logout();
    }
  };

  useEffect(() => {
    const fetchUser = async (accessToken) => {
      const headers = new Headers({ 'Content-type': 'application/json' });
      headers.set('Authorization', `Bearer ${accessToken.token}`);
      const setError = ({ detail }) => {
        setMessage({ text: detail, style: 'error' });
      };
      fetchData({ url: `/user/`, headers, handleUnauthorized, setData: setUser, setError });
    };
    if (accessToken) {
      fetchUser(accessToken);
    }
  }, [accessToken]);

  const value = useMemo(() => {
    return {
      accessToken,
      refreshToken,
      login,
      logout,
      handleUnauthorized,
      user,
    };
  }, [accessToken, refreshToken, login, logout, setMessage]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
