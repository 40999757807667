import { useState } from 'react';

export function useLocalStorage<T>(key: string, defaultValue?: T): [T | null, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const value = window.localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      } else {
        if (defaultValue) {
          window.localStorage.setItem(key, JSON.stringify(defaultValue));
          return defaultValue;
        } else {
          return null;
        }
      }
    } catch (err) {
      return defaultValue || null;
    }
  });
  const setValue = (newValue: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(newValue));
    } catch (err) {
      console.error(err);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
}

export default useLocalStorage;
