import React, { forwardRef, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

export interface ILinkProps extends MuiLinkProps {
  to?: string;
  children: ReactNode;
}

const Link = forwardRef<HTMLAnchorElement, ILinkProps>(({ to, children, ...props }, ref) => {
  return (
    <MuiLink component={RouterLink} to={to} ref={ref} {...props}>
      {children}
    </MuiLink>
  );
});

Link.displayName = 'app-link';

export default Link;
