import { useCallback, useState } from 'react';
import { useRequestHeaders } from './useHeaders';
import { useFlash } from './useFlash';
import { useAuth } from './useAuth';
import { IFlashMessage } from '../contexts';
import { CONFIG, IError, IFormError, i18n } from '../common';

type PostResponse<T> = {
  data: T;
  headers: Response['headers'];
  error?: IError | undefined;
  errors?: IFormError | undefined;
};

type PostRequest = {
  body?: object;
  params?: string;
  method?: string;
};

const genericError: IFlashMessage = {
  text: i18n.en.flashMessages.genericError,
  style: 'error',
};

export const usePost = <T>() => {
  const [loading, setLoading] = useState(false);
  const headers = useRequestHeaders();
  const { handleUnauthorized } = useAuth();
  const { setMessage } = useFlash();

  const post = useCallback(
    async (url: string, args?: PostRequest): Promise<PostResponse<T>> => {
      const requestConfig = args || {};
      try {
        setLoading(true);
        const response = await fetch(
          `${CONFIG.API_ENDPOINT}${url}${requestConfig.params ? `?${requestConfig.params}` : ''}`,
          {
            method: requestConfig.method || 'POST',
            headers,
            body: JSON.stringify(requestConfig.body),
          },
        );
        let errors;
        let error;
        const data = await response.json();

        if (!response.ok) {
          switch (response.status) {
            case 401:
              handleUnauthorized({ notify: true, refreshCallback: () => post(url, args) });
              break;
            case 405:
              setMessage(genericError);
              break;
          }
          if (data.detail) {
            error = data;
          } else {
            errors = data;
          }
        }

        setLoading(false);
        return { data, headers: response.headers, error, errors };
      } catch (error) {
        setMessage(genericError);
        console.error(error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [headers, handleUnauthorized, setMessage],
  );

  return {
    post,
    loading,
  };
};
