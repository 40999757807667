import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider, FlashMessageProvider } from './contexts';
import { Layout } from './layouts';
import { Authenticated, UnAuthenticated } from './middleware/auth';
import Home from './pages/home';
import Login from './pages/login';
import { QuestionnaireDetail, NewQuestionnaire, EditQuestionnaire } from './pages/questionnaire/';
import { NewQuestion, EditQuestion } from './pages/question/';
import { RunQuestionnaire } from './pages/questionnaireRun';
// import { NotFound } from './pages/errors'

const handler = (title) => {
  return {
    crumb: () => title,
  };
};

const idHandler = (key) => {
  return {
    crumb: (match) => match.params[key],
  };
};

const publicRoutes = {
  path: '',
  element: (
    <UnAuthenticated>
      <Login />
    </UnAuthenticated>
  ),
};

const questionRoutes = [
  {
    path: '',
    element: <QuestionnaireDetail />,
  },
  {
    path: 'new',
    element: <NewQuestion />,
    handle: handler('New'),
  },
  {
    path: ':questionId/edit',
    element: <EditQuestion />,
    handle: handler('Edit'),
  },
];

const questionnaireSpecificRoutes = [
  {
    path: '',
    element: <QuestionnaireDetail />,
  },
  {
    path: 'edit',
    element: <EditQuestionnaire />,
    handle: handler('Edit'),
  },
  {
    path: 'questions',
    handle: handler('Questions'),
    children: questionRoutes,
  },
];

const questionnaireRoutes = [
  {
    path: '',
    handle: handler('Questionnaires'),
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'new',
        element: <NewQuestionnaire />,
        handle: handler('New'),
      },
      {
        path: ':questionnaireId',
        handle: idHandler('questionnaireId'),
        children: questionnaireSpecificRoutes,
      },
    ],
  },
];

const runRoutes = [
  {
    path: ':runId',
    element: <RunQuestionnaire />,
  },
];

const router = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <Layout />
      </AuthProvider>
    ),
    children: [
      publicRoutes,
      {
        element: <Authenticated />,
        children: [
          {
            path: 'questionnaires',
            handle: handler('Questionnaires'),
            children: questionnaireRoutes,
          },
          {
            path: 'run',
            children: runRoutes,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <FlashMessageProvider>
      <RouterProvider router={router} />
    </FlashMessageProvider>
  );
}

export default App;
