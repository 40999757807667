import React from 'react';
import { i18n } from '../../common';
import { Box, Container, Grid, Typography } from '@mui/material';
import { FixedBottom } from '../fixedBottom';
import ButtonLink from '../buttonLink';

export const SuccessScreen = () => {
  const { title, message, button } = i18n.en.questionnaireRun.success;

  const bottomContent = (
    <Container maxWidth="sm">
      <Grid container direction="column">
        <ButtonLink variant="contained" href={`/questionnaires`} color="primary">
          {button}
        </ButtonLink>
      </Grid>
    </Container>
  );

  return (
    <FixedBottom bottomContent={bottomContent}>
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom>{message}</Typography>
      </Box>
    </FixedBottom>
  );
};
