import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

export function Spinner() {
  return (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  );
}
