import React, { useState } from 'react';
import { IQuestion, i18n } from '../../common';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import ButtonLink from '../buttonLink';
import Modal from '../modal';
import { useFlash, usePost } from '../../hooks';

interface RemoveDialogProps {
  question: IQuestion;
  questionnaireId: string;
  handleClose: () => void;
  handleSuccess: (IQuestion) => void;
}

export function RemoveDialog({ question, questionnaireId, handleClose, handleSuccess }: RemoveDialogProps) {
  const { loading, post } = usePost();
  const { setMessage } = useFlash();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { error } = await post(`/questionnaires/${questionnaireId}/questions/${question.id}/assign`, {
      method: 'DELETE',
    });
    if (error) {
      setMessage({ text: error.detail, style: 'error' });
    } else {
      setMessage({ text: i18n.en.actions.removed(i18n.en.models.question), style: 'success' });
      handleSuccess(question);
    }
  };

  return (
    <Modal ariaLabelledby="remove-question-modal-title" open={Boolean(question)} onClose={handleClose}>
      <Typography id="remove-question-modal-title" variant="h6" component="h2" gutterBottom>
        {i18n.en.controls.removeModel(i18n.en.models.question)}
      </Typography>
      <Grid
        container
        direction="column"
        sx={{
          mt: 4,
        }}
      >
        <Button onClick={submitHandler} color="error" variant="contained" startIcon={<Delete />} disabled={loading}>
          {i18n.en.controls.confirm}
        </Button>
      </Grid>
    </Modal>
  );
}

export function QuestionList({ questions, questionnaireId }: { questions: IQuestion[]; questionnaireId: string }) {
  const [questionList, setQuestionList] = useState<IQuestion[]>(questions);
  const [removeModalOpen, setRemoveModalOpen] = useState<IQuestion | false>(false);
  const baseUrl = `/questionnaires/${questionnaireId}/questions`;

  const handleClose = () => {
    setRemoveModalOpen(false);
  };

  const removeSuccessHandler = (question: IQuestion) => {
    const filtered = questionList.filter(({ id }) => id != question.id);
    setQuestionList(filtered);
    handleClose();
  };

  return (
    <>
      {removeModalOpen ? (
        <RemoveDialog
          question={removeModalOpen}
          questionnaireId={questionnaireId}
          handleClose={handleClose}
          handleSuccess={removeSuccessHandler}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell align="right">
                <ButtonLink variant="contained" href={`${baseUrl}/new`} color="success">
                  <Add />
                </ButtonLink>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!questions.length ? (
              <TableRow>
                <TableCell colSpan={2}>{i18n.en.list.empty(i18n.en.models.questions)}</TableCell>
              </TableRow>
            ) : (
              questions.map((question) => (
                <TableRow
                  key={question.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {question.question}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonLink href={`${baseUrl}/${question.id}/edit`} variant="text">
                      <Edit />
                    </ButtonLink>
                    <Button onClick={() => setRemoveModalOpen(question)} variant="text">
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
